
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "bd4a912f-13c5-4717-b0e5-42e1a249cb98"
  },
  "firebaseConfig": {
    "apiKey": "AIzaSyDxTZampLTW-tc7GVQf0DlUGL9Fc57NdQc",
    "authDomain": "jammy-trip-dev.firebaseapp.com",
    "projectId": "jammy-trip-dev",
    "storageBucket": "jammy-trip-dev.appspot.com",
    "messagingSenderId": "837045325995",
    "appId": "1:837045325995:web:bdce515e5950d27ec6a7ee"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "auth": true,
    "config": {
      "apiKey": "AIzaSyDxTZampLTW-tc7GVQf0DlUGL9Fc57NdQc",
      "authDomain": "jammy-trip-dev.firebaseapp.com",
      "projectId": "jammy-trip-dev",
      "storageBucket": "jammy-trip-dev.appspot.com",
      "messagingSenderId": "837045325995",
      "appId": "1:837045325995:web:bdce515e5950d27ec6a7ee"
    }
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
