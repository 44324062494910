import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Eorgdr12jA from "/opt/build/repo/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_t2GMTTFnMT from "/opt/build/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import composition_TNUJsc4G2r from "/opt/build/repo/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/composition.mjs";
import i18n_wvJtu1yLPy from "/opt/build/repo/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.mjs";
import plugin_client_mik7N71GmK from "/opt/build/repo/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import templates_plugin_7e4f6da8_cLvyS16cBv from "/opt/build/repo/.nuxt/templates.plugin.7e4f6da8.ejs";
import plugin_client_mLgZJrmjnn from "/opt/build/repo/node_modules/nuxt-vuefire/dist/runtime/auth/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_client_Eorgdr12jA,
  plugin_t2GMTTFnMT,
  composition_TNUJsc4G2r,
  i18n_wvJtu1yLPy,
  plugin_client_mik7N71GmK,
  templates_plugin_7e4f6da8_cLvyS16cBv,
  plugin_client_mLgZJrmjnn,
  chunk_reload_client_UciE0i6zes
]