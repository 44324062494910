import { default as indexQHLGOCDfydMeta } from "/opt/build/repo/pages/account/index.vue?macro=true";
import { default as indexwjElgkyyLmMeta } from "/opt/build/repo/pages/contact/index.vue?macro=true";
import { default as indexWVSQuX67eqMeta } from "/opt/build/repo/pages/faq/index.vue?macro=true";
import { default as finish_45registervQLWrviSvpMeta } from "/opt/build/repo/pages/finish-register.vue?macro=true";
import { default as forgot_45password6Wrw3WyRqiMeta } from "/opt/build/repo/pages/forgot-password.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as interestskmh9Ds0rXDMeta } from "/opt/build/repo/pages/interests.vue?macro=true";
import { default as itinerariesVBDXRcACbAMeta } from "/opt/build/repo/pages/itineraries.vue?macro=true";
import { default as edit5X1VQ2EZATMeta } from "/opt/build/repo/pages/itinerary/[id]/edit.vue?macro=true";
import { default as index1sP4X5roPxMeta } from "/opt/build/repo/pages/itinerary/[id]/index.vue?macro=true";
import { default as notesfbX4ZAy6SpMeta } from "/opt/build/repo/pages/itinerary/[id]/notes.vue?macro=true";
import { default as placeOTheqVMRLsMeta } from "/opt/build/repo/pages/itinerary/[id]/place.vue?macro=true";
import { default as shareVTHmoj3TodMeta } from "/opt/build/repo/pages/itinerary/[id]/share.vue?macro=true";
import { default as finish9xsaBGCXEDMeta } from "/opt/build/repo/pages/itinerary/[id]/stage/[stageId]/add/finish.vue?macro=true";
import { default as indexb5R0occ7c5Meta } from "/opt/build/repo/pages/itinerary/[id]/stage/[stageId]/add/index.vue?macro=true";
import { default as edit7RgzrpWHmbMeta } from "/opt/build/repo/pages/itinerary/[id]/stage/[stageId]/edit.vue?macro=true";
import { default as errordHgDpicA7lMeta } from "/opt/build/repo/pages/itinerary/[id]/stage/[stageId]/error.vue?macro=true";
import { default as indexuTMRKepQqtMeta } from "/opt/build/repo/pages/itinerary/[id]/stage/[stageId]/index.vue?macro=true";
import { default as indexuYqSFwQRxWMeta } from "/opt/build/repo/pages/itinerary/[id]/stage/index.vue?macro=true";
import { default as indexcEVJNzy9CgMeta } from "/opt/build/repo/pages/itinerary/index.vue?macro=true";
import { default as indexossDFwLGqNMeta } from "/opt/build/repo/pages/jammy-itineraries/[id]/index.vue?macro=true";
import { default as index824Elqsf2fMeta } from "/opt/build/repo/pages/jammy-itineraries/index.vue?macro=true";
import { default as indexNSbnZ3UHG5Meta } from "/opt/build/repo/pages/language/index.vue?macro=true";
import { default as index9WruYoq02MMeta } from "/opt/build/repo/pages/login/index.vue?macro=true";
import { default as startMv58tnlA1DMeta } from "/opt/build/repo/pages/login/start.vue?macro=true";
import { default as logoutlLSqGnk5PzMeta } from "/opt/build/repo/pages/logout.vue?macro=true";
import { default as on_45boardingpgDQHkIqlLMeta } from "/opt/build/repo/pages/on-boarding.vue?macro=true";
import { default as index6KfH7kjDgOMeta } from "/opt/build/repo/pages/privacy/index.vue?macro=true";
import { default as finishQrpkcm6B7wMeta } from "/opt/build/repo/pages/register/finish.vue?macro=true";
import { default as indexllN7HpClHUMeta } from "/opt/build/repo/pages/register/index.vue?macro=true";
import { default as indexmkmg3oLmh6Meta } from "/opt/build/repo/pages/shop/index.vue?macro=true";
import { default as indexsW9ityWRCFMeta } from "/opt/build/repo/pages/terms/index.vue?macro=true";
export default [
  {
    name: indexQHLGOCDfydMeta?.name ?? "account",
    path: indexQHLGOCDfydMeta?.path ?? "/account",
    meta: indexQHLGOCDfydMeta || {},
    alias: indexQHLGOCDfydMeta?.alias || [],
    redirect: indexQHLGOCDfydMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexwjElgkyyLmMeta?.name ?? "contact",
    path: indexwjElgkyyLmMeta?.path ?? "/contact",
    meta: indexwjElgkyyLmMeta || {},
    alias: indexwjElgkyyLmMeta?.alias || [],
    redirect: indexwjElgkyyLmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexWVSQuX67eqMeta?.name ?? "faq",
    path: indexWVSQuX67eqMeta?.path ?? "/faq",
    meta: indexWVSQuX67eqMeta || {},
    alias: indexWVSQuX67eqMeta?.alias || [],
    redirect: indexWVSQuX67eqMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: finish_45registervQLWrviSvpMeta?.name ?? "finish-register",
    path: finish_45registervQLWrviSvpMeta?.path ?? "/finish-register",
    meta: finish_45registervQLWrviSvpMeta || {},
    alias: finish_45registervQLWrviSvpMeta?.alias || [],
    redirect: finish_45registervQLWrviSvpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/finish-register.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password6Wrw3WyRqiMeta?.name ?? "forgot-password",
    path: forgot_45password6Wrw3WyRqiMeta?.path ?? "/forgot-password",
    meta: forgot_45password6Wrw3WyRqiMeta || {},
    alias: forgot_45password6Wrw3WyRqiMeta?.alias || [],
    redirect: forgot_45password6Wrw3WyRqiMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: interestskmh9Ds0rXDMeta?.name ?? "interests",
    path: interestskmh9Ds0rXDMeta?.path ?? "/interests",
    meta: interestskmh9Ds0rXDMeta || {},
    alias: interestskmh9Ds0rXDMeta?.alias || [],
    redirect: interestskmh9Ds0rXDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/interests.vue").then(m => m.default || m)
  },
  {
    name: itinerariesVBDXRcACbAMeta?.name ?? "itineraries",
    path: itinerariesVBDXRcACbAMeta?.path ?? "/itineraries",
    meta: itinerariesVBDXRcACbAMeta || {},
    alias: itinerariesVBDXRcACbAMeta?.alias || [],
    redirect: itinerariesVBDXRcACbAMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itineraries.vue").then(m => m.default || m)
  },
  {
    name: edit5X1VQ2EZATMeta?.name ?? "itinerary-id-edit",
    path: edit5X1VQ2EZATMeta?.path ?? "/itinerary/:id()/edit",
    meta: edit5X1VQ2EZATMeta || {},
    alias: edit5X1VQ2EZATMeta?.alias || [],
    redirect: edit5X1VQ2EZATMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index1sP4X5roPxMeta?.name ?? "itinerary-id",
    path: index1sP4X5roPxMeta?.path ?? "/itinerary/:id()",
    meta: index1sP4X5roPxMeta || {},
    alias: index1sP4X5roPxMeta?.alias || [],
    redirect: index1sP4X5roPxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: notesfbX4ZAy6SpMeta?.name ?? "itinerary-id-notes",
    path: notesfbX4ZAy6SpMeta?.path ?? "/itinerary/:id()/notes",
    meta: notesfbX4ZAy6SpMeta || {},
    alias: notesfbX4ZAy6SpMeta?.alias || [],
    redirect: notesfbX4ZAy6SpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/notes.vue").then(m => m.default || m)
  },
  {
    name: placeOTheqVMRLsMeta?.name ?? "itinerary-id-place",
    path: placeOTheqVMRLsMeta?.path ?? "/itinerary/:id()/place",
    meta: placeOTheqVMRLsMeta || {},
    alias: placeOTheqVMRLsMeta?.alias || [],
    redirect: placeOTheqVMRLsMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/place.vue").then(m => m.default || m)
  },
  {
    name: shareVTHmoj3TodMeta?.name ?? "itinerary-id-share",
    path: shareVTHmoj3TodMeta?.path ?? "/itinerary/:id()/share",
    meta: shareVTHmoj3TodMeta || {},
    alias: shareVTHmoj3TodMeta?.alias || [],
    redirect: shareVTHmoj3TodMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/share.vue").then(m => m.default || m)
  },
  {
    name: finish9xsaBGCXEDMeta?.name ?? "itinerary-id-stage-stageId-add-finish",
    path: finish9xsaBGCXEDMeta?.path ?? "/itinerary/:id()/stage/:stageId()/add/finish",
    meta: finish9xsaBGCXEDMeta || {},
    alias: finish9xsaBGCXEDMeta?.alias || [],
    redirect: finish9xsaBGCXEDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/stage/[stageId]/add/finish.vue").then(m => m.default || m)
  },
  {
    name: indexb5R0occ7c5Meta?.name ?? "itinerary-id-stage-stageId-add",
    path: indexb5R0occ7c5Meta?.path ?? "/itinerary/:id()/stage/:stageId()/add",
    meta: indexb5R0occ7c5Meta || {},
    alias: indexb5R0occ7c5Meta?.alias || [],
    redirect: indexb5R0occ7c5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/stage/[stageId]/add/index.vue").then(m => m.default || m)
  },
  {
    name: edit7RgzrpWHmbMeta?.name ?? "itinerary-id-stage-stageId-edit",
    path: edit7RgzrpWHmbMeta?.path ?? "/itinerary/:id()/stage/:stageId()/edit",
    meta: edit7RgzrpWHmbMeta || {},
    alias: edit7RgzrpWHmbMeta?.alias || [],
    redirect: edit7RgzrpWHmbMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/stage/[stageId]/edit.vue").then(m => m.default || m)
  },
  {
    name: errordHgDpicA7lMeta?.name ?? "itinerary-id-stage-stageId-error",
    path: errordHgDpicA7lMeta?.path ?? "/itinerary/:id()/stage/:stageId()/error",
    meta: errordHgDpicA7lMeta || {},
    alias: errordHgDpicA7lMeta?.alias || [],
    redirect: errordHgDpicA7lMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/stage/[stageId]/error.vue").then(m => m.default || m)
  },
  {
    name: indexuTMRKepQqtMeta?.name ?? "itinerary-id-stage-stageId",
    path: indexuTMRKepQqtMeta?.path ?? "/itinerary/:id()/stage/:stageId()",
    meta: indexuTMRKepQqtMeta || {},
    alias: indexuTMRKepQqtMeta?.alias || [],
    redirect: indexuTMRKepQqtMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/stage/[stageId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuYqSFwQRxWMeta?.name ?? "itinerary-id-stage",
    path: indexuYqSFwQRxWMeta?.path ?? "/itinerary/:id()/stage",
    meta: indexuYqSFwQRxWMeta || {},
    alias: indexuYqSFwQRxWMeta?.alias || [],
    redirect: indexuYqSFwQRxWMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/[id]/stage/index.vue").then(m => m.default || m)
  },
  {
    name: indexcEVJNzy9CgMeta?.name ?? "itinerary",
    path: indexcEVJNzy9CgMeta?.path ?? "/itinerary",
    meta: indexcEVJNzy9CgMeta || {},
    alias: indexcEVJNzy9CgMeta?.alias || [],
    redirect: indexcEVJNzy9CgMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/itinerary/index.vue").then(m => m.default || m)
  },
  {
    name: indexossDFwLGqNMeta?.name ?? "jammy-itineraries-id",
    path: indexossDFwLGqNMeta?.path ?? "/jammy-itineraries/:id()",
    meta: indexossDFwLGqNMeta || {},
    alias: indexossDFwLGqNMeta?.alias || [],
    redirect: indexossDFwLGqNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/jammy-itineraries/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index824Elqsf2fMeta?.name ?? "jammy-itineraries",
    path: index824Elqsf2fMeta?.path ?? "/jammy-itineraries",
    meta: index824Elqsf2fMeta || {},
    alias: index824Elqsf2fMeta?.alias || [],
    redirect: index824Elqsf2fMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/jammy-itineraries/index.vue").then(m => m.default || m)
  },
  {
    name: indexNSbnZ3UHG5Meta?.name ?? "language",
    path: indexNSbnZ3UHG5Meta?.path ?? "/language",
    meta: indexNSbnZ3UHG5Meta || {},
    alias: indexNSbnZ3UHG5Meta?.alias || [],
    redirect: indexNSbnZ3UHG5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/language/index.vue").then(m => m.default || m)
  },
  {
    name: index9WruYoq02MMeta?.name ?? "login",
    path: index9WruYoq02MMeta?.path ?? "/login",
    meta: index9WruYoq02MMeta || {},
    alias: index9WruYoq02MMeta?.alias || [],
    redirect: index9WruYoq02MMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: startMv58tnlA1DMeta?.name ?? "login-start",
    path: startMv58tnlA1DMeta?.path ?? "/login/start",
    meta: startMv58tnlA1DMeta || {},
    alias: startMv58tnlA1DMeta?.alias || [],
    redirect: startMv58tnlA1DMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login/start.vue").then(m => m.default || m)
  },
  {
    name: logoutlLSqGnk5PzMeta?.name ?? "logout",
    path: logoutlLSqGnk5PzMeta?.path ?? "/logout",
    meta: logoutlLSqGnk5PzMeta || {},
    alias: logoutlLSqGnk5PzMeta?.alias || [],
    redirect: logoutlLSqGnk5PzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: on_45boardingpgDQHkIqlLMeta?.name ?? "on-boarding",
    path: on_45boardingpgDQHkIqlLMeta?.path ?? "/on-boarding",
    meta: on_45boardingpgDQHkIqlLMeta || {},
    alias: on_45boardingpgDQHkIqlLMeta?.alias || [],
    redirect: on_45boardingpgDQHkIqlLMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/on-boarding.vue").then(m => m.default || m)
  },
  {
    name: index6KfH7kjDgOMeta?.name ?? "privacy",
    path: index6KfH7kjDgOMeta?.path ?? "/privacy",
    meta: index6KfH7kjDgOMeta || {},
    alias: index6KfH7kjDgOMeta?.alias || [],
    redirect: index6KfH7kjDgOMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: finishQrpkcm6B7wMeta?.name ?? "register-finish",
    path: finishQrpkcm6B7wMeta?.path ?? "/register/finish",
    meta: finishQrpkcm6B7wMeta || {},
    alias: finishQrpkcm6B7wMeta?.alias || [],
    redirect: finishQrpkcm6B7wMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/register/finish.vue").then(m => m.default || m)
  },
  {
    name: indexllN7HpClHUMeta?.name ?? "register",
    path: indexllN7HpClHUMeta?.path ?? "/register",
    meta: indexllN7HpClHUMeta || {},
    alias: indexllN7HpClHUMeta?.alias || [],
    redirect: indexllN7HpClHUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexmkmg3oLmh6Meta?.name ?? "shop",
    path: indexmkmg3oLmh6Meta?.path ?? "/shop",
    meta: indexmkmg3oLmh6Meta || {},
    alias: indexmkmg3oLmh6Meta?.alias || [],
    redirect: indexmkmg3oLmh6Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexsW9ityWRCFMeta?.name ?? "terms",
    path: indexsW9ityWRCFMeta?.path ?? "/terms",
    meta: indexsW9ityWRCFMeta || {},
    alias: indexsW9ityWRCFMeta?.alias || [],
    redirect: indexsW9ityWRCFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/terms/index.vue").then(m => m.default || m)
  }
]